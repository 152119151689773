<template>
    <div class="devway">
        <div class="way-item" v-for="item in touristRoutes" :key="item.id">
            <el-image style="width:100%;height:100%" :src="item.photoUrls[0]"></el-image>
            <div class="infos" @click="$router.push(`/bestwaydetail?id=${item.id}`)">
                <div class="name">{{item.name}}</div>
                <div class="btn"><i class="el-icon-right"></i></div>
            </div>
        </div>
    </div>
</template>
<script>
import { GetTouristRoutesPage, GetTouristPoints } from '@/api/home'
export default {
    data(){
        return{
            touristRoutes: [], //旅游线路
            touristRoute1: null,
            touristPoints: [], //旅游线路点
        }
    },
    created(){
    },
    mounted(){
        this.getRouristRoutes();
    },
    methods:{
        //获取旅游线路(徒步自驾)
        getRouristRoutes(){
            const data = { filter:{routesTypes:'徒步自驾'}, currentPage:1, pageSize:3 };
            GetTouristRoutesPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                    })
                    this.touristRoutes = data;
                }
            })
        },
        
    },
}
</script>
<style scoped>
.devway{
    display: flex;
    justify-content: space-between;
}
.way-item{
    width: 33.3%;
    height: 360px;
    position: relative;
}
.infos{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .4);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
}
.name{
    font-size: 20px;
    font-weight: bold;
    line-height: 50px;
    text-align: center;
}
.btn{
    font-size: 32px;
    text-align: center;
}
.infos:hover{
    background: rgba(0, 0, 0, 0);
}
.infos:hover .name,.infos:hover .btn{
    opacity: 0;
}
</style>
