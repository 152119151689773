<!--趣味采摘-->
<template>
    <div class="picking">
        <div class="left view" @click="$router.push(`/sharehousedetail?id=${p1.id}`)">
            <el-image fit="cover" style="width:100%;height:100%"  :src="p1.photoUrls[0]"></el-image>
            <div class="title" v-show="p1.id !== actItem.id">{{p1.name}}</div>
            
        </div>
        <div class="right">
            <div class="r-t view" @click="$router.push(`/sharehousedetail?id=${p2.id}`)">
                <el-image fit="cover" style="width:100%;height:100%"  :src="p2.photoUrls[0]"></el-image>
                <div class="title" v-show="p2.id !== actItem.id">{{p2.name}}</div>
            </div>
            <div class="r-b">
                <div class="rb-t view" @click="$router.push(`/sharehousedetail?id=${p3.id}`)">
                    <el-image fit="cover" style="width:100%;height:100%"  :src="p3.photoUrls[0]"></el-image>
                    <div class="title" v-show="p3.id !== actItem.id">{{p3.name}}</div>
                </div>
                <div class="rb-b view" @click="$router.push(`/sharehousedetail?id=${p4.id}`)">
                    <el-image fit="cover" style="width:100%;height:100%"  :src="p4.photoUrls[0]"></el-image>
                    <div class="title" v-show="p4.id !== actItem.id">{{p4.name}}</div>
                </div>
            </div>
        </div>
        <div class="info">
            <div class="name ellipsis">{{actItem.name}}</div>
            <div class="text">{{actItem.playItems&&actItem.playItems.replace(',',' | ')}}</div>
        </div>
    </div>
</template>
<script>
import { GetSharedFarmPage } from '@/api/home'
export default {
    data(){
        return{
            sharedFarms: [], //共享农庄
            sharedFarm1: null,
            sharedFarmsZdtj: [], //共享农庄/重点推荐
            p1:{photoUrls:[require('../../../public/img/xnh/xny/pick1.png')],name:'演示数据',introduction:'演示数据'},
            p2:{photoUrls:[require('../../../public/img/xnh/xny/pick2.png')],name:'演示数据',introduction:'演示数据'},
            p3:{photoUrls:[require('../../../public/img/xnh/xny/pick3.png')],name:'演示数据',introduction:'演示数据'},
            p4:{photoUrls:[require('../../../public/img/xnh/xny/pick4.png')],name:'演示数据',introduction:'演示数据'},
            actItem:{},
        }
    },
    created(){
    },
    mounted(){
        // this.getSharedFarms();
        this.getSharedFarmsZdtj();
        let oDiv = document.getElementsByClassName('picking')[0];
        let info = document.getElementsByClassName('info')[0];
        let that = this
        oDiv.addEventListener('mouseenter',function(e){
            if(e.target.className.indexOf('left')>-1 ){
                info.style.width = '270px'
                info.style.left = '165px'
                info.style.top = '230px'
                that.actItem = that.p1
            }else if(e.target.className.indexOf('r-t')>-1 ){
                info.style.width = '270px'
                info.style.left = '820px'
                info.style.top = '50px'
                that.actItem = that.p2
            }else if(e.target.className.indexOf('rb-t')>-1 ){
                info.style.width = '270px'
                info.style.left = '657.5px'
                info.style.top = '370px'
                that.actItem = that.p3
            }else if(e.target.className.indexOf('rb-b')>-1 ){
                info.style.width = '270px'
                info.style.left = '992.5px'
                info.style.top = '370px'
                that.actItem = that.p4
            }

        },true);
    },
    methods:{
        //获取共享农庄(采摘园)
        getSharedFarms(){
            const data = { filter:{businessType:'采摘园'}, currentPage:1, pageSize:4 };
            GetSharedFarmPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                    })
                    this.sharedFarms = data;
                    //第一个拿出单独显示
                    if (this.sharedFarms.length > 1) {
                        var arr = this.sharedFarms.splice(0, 1);
                        this.sharedFarm1 = arr[0];
                    }
                    
                }
            })
        },
        //获取共享农庄/重点推荐(采摘园)
        getSharedFarmsZdtj(){
            const data = { filter:{businessType:'采摘园'}, currentPage:1, pageSize:4, orderName:'Sort', orderDesc: false };
            GetSharedFarmPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach((d,k) => {
                        d.photoUrls = d.photoUrls.split(',');
                        this[`p${k+1}`] = d;
                    })
                    this.foods = data;
                    this.actItem = this.foods[0];
                }
            })
        },
    },
}
</script>
<style scoped>
.imgbox{
    height: 100%;
}
.view{
    cursor: pointer;
}
.title{
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(to top,rgba(0, 0, 0,.9) 0%,rgba(0, 0, 0,.7) 40%, transparent);
    width: 100%;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 14px;
}
.left{
    width: 610px;
    position: relative;
}
.right{
    width: calc(100% - 640px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 100%; */
}
.r-t{
    height: 260px;
    background-color: #888;
    position: relative;
}
.r-b{
    height: 320px;
    display: flex;
    justify-content: space-between;
}
.rb-t,.rb-b{
    width: 305px;
    background-color: #eee;
    position: relative;
}
.picking{
    display: flex;
    justify-content: space-between;
    height: 610px;
    overflow: hidden;
    position: relative;
}
.info{
    position: absolute;
    top: 230px;
    left: 165px;
    width: 270px;
    height: 160px;
    /* background: rgba(255, 255, 255, .7); */
    /* position: relative; */
    display: flex;
    flex-direction:column;
    padding: 30px 32px 0;
    justify-content: center;
    transition: all .8s ;
    overflow: hidden;
}
.info::before{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(../../assets/img/hot-icon/sub_bg.png) no-repeat center center;
    background-size: 100% 100%;
    z-index: 0;
    opacity: .8;
}
.info>.name{
    font-size:18px;
    color: #367bff;
    font-weight: bold;
    text-align: center;
    position: relative;
    z-index: 2;
}
.info>.text{
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    margin-top: 12px;
    font-weight: bold;
    color: #367bff;
    position: relative;
    z-index: 2;
    /* display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:1;
    -webkit-box-orient:vertical; */
}
</style>