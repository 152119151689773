<template>
    <div class="festival">
        <div class="left">
            <div class="p1 box" @click="goDetail(p1.id)">
                <el-image fit="cover" style="width:100%;height:100%"  :src="p1.photoUrls[0]"></el-image>
                <div class="title">{{p1.title}}</div>
            </div>
            <div class="p2 box" @click="goDetail(p2.id)">
                <el-image fit="cover" style="width:100%;height:100%"  :src="p2.photoUrls[0]"></el-image>
                <div class="title">{{p2.title}}</div>
            </div>
        </div>
        <div class="center">
            <div class="p3 box" @click="goDetail(p3.id)">
                <el-image fit="cover" style="width:100%;height:100%"  :src="p3.photoUrls[0]"></el-image>
                <div class="title">{{p3.title}}</div>
            </div>
            <div class="p4 box" @click="goDetail(p4.id)">
                <el-image fit="cover" style="width:100%;height:100%"  :src="p4.photoUrls[0]"></el-image>
                <div class="title">{{p4.title}}</div>
            </div>
        </div>
        <div class="right">
            <div class="p5 box" @click="goDetail(p5.id)">
                <el-image fit="cover" style="width:100%;height:100%"  :src="p5.photoUrls[0]"></el-image>
                <div class="title">{{p5.title}}</div>
            </div>
            <div class="p6 box" @click="goDetail(p6.id)">
                <el-image fit="cover" style="width:100%;height:100%"  :src="p6.photoUrls[0]"></el-image>
                <div class="title">{{p6.title}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { GetInformationPage } from "@/api/home";
import router from "../../router";
export default {
    data() {
        return {
            pageData: [],
            params: {
                currentPage: 1,
                filter: { infoType: "249251840589893" },
                pageSize: 6,
            },
            p1:{photoUrls:[require('../../../public/img/xnh/xny/pick1.png')],title:'演示数据',introduction:'演示数据'},
            p2:{photoUrls:[require('../../../public/img/xnh/xny/pick2.png')],title:'演示数据',introduction:'演示数据'},
            p3:{photoUrls:[require('../../../public/img/xnh/xny/pick3.png')],title:'演示数据',introduction:'演示数据'},
            p4:{photoUrls:[require('../../../public/img/xnh/xny/pick4.png')],title:'演示数据',introduction:'演示数据'},
            p5:{photoUrls:[require('../../../public/img/xnh/xny/pick4.png')],title:'演示数据',introduction:'演示数据'},
            p6:{photoUrls:[require('../../../public/img/xnh/xny/pick4.png')],title:'演示数据',introduction:'演示数据'},
        };
    },
    mounted() {
        this.getInfopage();
    },
    methods:{
        goDetail(id){
            id&&this.$router.push(`/detail?id=${id}&type=特色节庆&oParams=${JSON.stringify(this.params)}`)
        },
        getInfopage() {
            GetInformationPage(this.params).then((res) => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach((d,k) => {
                        d.photoUrls = d.photoUrls.split(',');
                        this[`p${k+1}`] = d;
                    })
                }
            });
        },
    }
    
}
</script>
<style scoped>
.box:hover{
    transform: scale(1.1);
}
.p1{
    height: 274px;
    width: 100%;
}
.p2{
    width: 310px;
    height: 284px;
    margin-top: 30px;
}
.p3{
    width: 100%;
    height: 440px;
}
.p4{
    width: 100%;
    height: 280px;
    margin-top: 30px;
}
.p5{
    width: 100%;
    height: 224px;
}
.p6{
    width: 100%;
    height: 422px;
    margin-top: 30px;
}
.title{
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(to top,rgba(0, 0, 0,.9) 0%,rgba(0, 0, 0,.7) 40%, transparent);
    width: 100%;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 14px;
}
.festival{
    display: flex;
    justify-content: space-between;
}
.left,.center,.right{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.left{
    width: 452px;
    height: 750px;
    align-items: flex-end;
}
.center{
    width: 400px;
    height: 750px;
}
.right{
    width: 370px;
    height: 750px;
}
.box{
    position: relative;
    transition: all .3s;
    cursor: pointer;
}
</style>