<template>
    <div class="xiangninghui-index">
        <!--美丽乡村-->
        <div class="village mt-20">
            <table></table>
            <div class="floor-title w-1400  mt-20">
                <img src="../../assets/img/xnh/title-go1.png" alt="">
            </div>
            <div class="mt-20 w-1280 pb-20">
                <village ></village>
                <div class="more" @click="$router.push('/villiagelist')">
                    <span>查看更多</span>
                    <img src="../../assets/img/hot-icon/more.png" alt="">
                </div>
            </div>
        </div>
        <!--趣味采摘-->
        <div class="funPicking mt-20">
            <table></table>
            <div class="floor-title w-1400  mt-20">
                <img src="../../assets/img/xnh/title-go2.png" alt="">
            </div>
            <div class="mt-20 w-1280 pb-20">
                <funPicking ></funPicking>
                <div class="more" @click="$router.push('/shareFarmList?businessType=采摘园')">
                    <span>查看更多</span>
                    <img src="../../assets/img/hot-icon/more.png" alt="">
                </div>
            </div>
        </div>
        <!--特色节庆-->
        <div class="festval mt-20">
            <table></table>
            <div class="floor-title w-1400  mt-20">
                <img src="../../assets/img/xnh/title-go3.png" alt="">
            </div>
            <div class="mt-20 w-1280 pb-20">
                <festival ></festival>
                <div class="more" @click="$router.push('/List?infoType=249251840589893&type=特色节庆')">
                    <span>查看更多</span>
                    <img src="../../assets/img/hot-icon/more.png" alt="">
                </div>
            </div>
        </div>
        <!--精品路线-->
        <div class="hotway mt-20">
            <table></table>
            <div class="floor-title w-1400  mt-20">
                <img src="../../assets/img/xnh/title-go4.png" alt="">
            </div>
            <div class="mt-20 w-1280 pb-20">
                <hotway ></hotway>
                <div class="more" @click="$router.push('/bestway?routesTypes=精品路线')">
                    <span>查看更多</span>
                    <img src="../../assets/img/hot-icon/more.png" alt="">
                </div>
            </div>
        </div>
        <!--徒步自驾-->
        <div class="hotway mt-20">
            <table></table>
            <div class="floor-title w-1400  mt-20">
                <img src="../../assets/img/xnh/title-go5.png" alt="">
            </div>
            <div class="mt-20 w-1280 pb-20">
                <devway ></devway>
                <div class="more" @click="$router.push(`/bestway?routesTypes=徒步自驾`)">
                    <span>查看更多</span>
                    <img src="../../assets/img/hot-icon/more.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import village from '../../components/xiangninghui/village.vue'
import funPicking from '../../components/xiangninghui/funPicking.vue'
import festival from '../../components/xiangninghui/festival.vue'
import hotway from '../../components/xiangninghui/hotway.vue'
import devway from '../../components/xiangninghui/devway.vue'
export default {
    components:{
        village,funPicking,festival,hotway,devway
    },
    data(){
        return{}
    },
    methods:{
    },
    mounted(){
    }
}
</script>
<style scoped>
.more{
    width: 240px;
    line-height: 42px;
    font-size: 16px;
    color: #898989;
    border: 2px solid #ccc;
    margin: 80px auto 40px auto;
    text-align: center;
    cursor: pointer;
    transition: all .8s;
}
.more:hover{
    background: rgb(211, 223, 240);
    /* border-color: #eee; */
    /* color: #fff; */
}
.more>span{
    margin-right: 20px;
}
.w-1400 {
    width: 1200px;
    margin: 20px auto;
}
.floor-title>span{
    letter-spacing: 12px;
    padding-bottom: 5px;
    border-bottom: 1px solid #3295f1;
}
.floor-title{
    color:#000;
    text-align: center;
    font-size: 40px;
    padding: 20px 0;
}
.mt-20 {
    margin-top: 20px;
}
</style>